class LessonAudio {
    constructor(selector) {
        this.selector = selector;
        this.file = this.selector.getAttribute('data-audio-file');
        this.audio = new Audio();
        this.play = false;

        if (this.file !== '') {
            this.audio.src = this.file;
        }

        this.selector.addEventListener('click', (e) => {
            e.preventDefault();

            if (this.play === false) {
                this.audio.play();
                this.play = true;
                this.selector.setAttribute('title', 'Zatrzymaj');
                this.selector.classList.add('lesson__audio-icon--pulse');
            } else {
                this.audio.pause();
                this.play = false;
                this.selector.setAttribute('title', 'Odsłuchaj');
                this.selector.classList.remove('lesson__audio-icon--pulse');
            }
        });

        this.audio.addEventListener('ended', () => {
            this.play = false;
            this.selector.setAttribute('title', 'Odsłuchaj');
            this.selector.classList.remove('lesson__audio-icon--pulse');
        });
    }
}
export default LessonAudio;