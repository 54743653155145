class ColoringBookModal {
    constructor(selector) {
        this.selector = selector;
        this.modal = document.querySelector('[data-coloring-book-modal]');
        this.modal_close = document.querySelector('[data-coloring-book-modal-close]');

        if (this.modal_close !== null) {
            this.modal_close.addEventListener('click', e => {
                e.preventDefault();

                if (this.modal !== null) {
                    this.modal.classList.remove('modal--shown');
                }
            });
        }

        this.selector.addEventListener('click', e => {
            e.preventDefault();

            if (this.modal !== null) {
                this.modal.classList.add('modal--shown');
            }
        });
    }
}
export default ColoringBookModal;