class Map {
    constructor(selector) {
        this.selector = selector;
        this.map_container = this.selector.querySelector('[data-element="map-container"]');
        this.points = this.selector.querySelectorAll('[data-element="map-point"]');
        this.table_information = this.selector.querySelector('[data-element="table-information"]');
        this.ti_content = this.table_information.querySelector('[data-element="content"]');
        this.inMobile = false;

        if (window.innerWidth <= 640) {
            this.isMobile = true;
        }

        if (this.points.length > 0) {
            this.points.forEach(point => {
               if (point.classList.contains('map__point--active')) {
                   let city = point.getAttribute('data-city');
                   let address = point.getAttribute('data-address');
                   let date = point.getAttribute('data-date');

                   // console.log(city, address, date, url);

                   let div_city = document.createElement('div');
                   div_city.classList.add('city');
                   div_city.innerHTML = city;

                   let div_address = document.createElement('div');
                   div_address.classList.add('address');
                   div_address.innerHTML = address;

                   let div_date = document.createElement('div');
                   div_date.classList.add('date');
                   div_date.innerHTML = date;

                   this.ti_content.innerHTML = '';
                   this.ti_content.appendChild(div_city);
                   this.ti_content.appendChild(div_address);
                   this.ti_content.appendChild(div_date);
               }

               this._pointClick = this._pointClick.bind(this);
               point.addEventListener('click', this._pointClick);
            });
        }
    }
    _pointClick(e) {
        // console.log(e);
        e.preventDefault();

        let point = e.target.closest('a');

        this.points.forEach(p => {
           if (p.classList.contains('map__point--active')) {
               p.classList.remove('map__point--active');
           }
        });
        point.classList.add('map__point--active');

        let city = point.getAttribute('data-city');
        let address = point.getAttribute('data-address');
        let date = point.getAttribute('data-date');

        // console.log(city, address, date, url);

        let div_city = document.createElement('div');
        div_city.classList.add('city');
        div_city.innerHTML = city;

        let div_address = document.createElement('div');
        div_address.classList.add('address');
        div_address.innerHTML = address;

        let div_date = document.createElement('div');
        div_date.classList.add('date');
        div_date.innerHTML = date;

        this.ti_content.innerHTML = '';
        this.ti_content.appendChild(div_city);
        this.ti_content.appendChild(div_address);
        this.ti_content.appendChild(div_date);

        if (this.isMobile) {
            window.scrollTo({
                top: this.table_information.getBoundingClientRect().top + window.pageYOffset - 200
            });
        }
    };
}
export default Map;