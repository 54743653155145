import Cookie from 'js-cookie';
import * as basicLightbox from "basiclightbox";

class MainQuiz {
    constructor(selector) {
        this.selector = selector;

        this.questionContainer = selector.querySelector('[data-element="question-container"]');
        this.scoreContainer = selector.querySelector('[data-element="score"]');
        this.formContainer = selector.querySelector('[data-element="form"]');

        this.question = selector.querySelector('[data-element="question"]');
        this.answersContainer = selector.querySelector('[data-element="answers"]');
        this.nextButton = selector.querySelector('[data-element="next"]');
        this.endButton = selector.querySelector('[data-element="end"]');
        this.startButton = selector.querySelector('[data-element="start"]');

        this.questionCount = selector.getAttribute('data-count');

        this.currentQuestion = 0;
        this.score = 0;
        this.startTime = 0;
        this.endTime = 0;
        this.fullTime = 0;

        this._nextQuestion = this._nextQuestion.bind(this);
        this._startQuiz = this._startQuiz.bind(this);
        this._sendForm = this._sendForm.bind(this);

        this.nextButton.addEventListener('click', this._nextQuestion);
        this.startButton.addEventListener('click', this._startQuiz);
    }

    _startQuiz(event){
        event.preventDefault();
        this.score = 0;
        this.currentQuestion = 0;
        this.nextButton.innerText = 'Dalej';
        this.nextButton.style.display = 'block';
        this.startButton.style.display = 'none';
        this.questionContainer.style.display = 'block';

        this._getQuestion(0).then(question => {
            this._generateQuestion(question);
            this.startTime = performance.now();
        }).catch(error => {
            this._handleError(error);
        });
    }

    _nextQuestion(event){
        event.preventDefault();
        this.endTime = performance.now();

        let timeSpent = this.endTime - this.startTime;
        this.fullTime += timeSpent;
        this.startTime = 0;
        this.endTime = 0;

        let checkedAnswer = this._getCheckedAnswer();
        if(checkedAnswer !== -1){
            this._checkAnswer(checkedAnswer).then(result => {
                if(result.correct){
                    this.score++;
                }
                if(result.nextQuestion !== null){
                    this._generateQuestion(result.nextQuestion);
                    this.currentQuestion++;
                    this.startTime = performance.now();

                    if (this.currentQuestion === this.questionCount-1) {
                        this.nextButton.innerText = 'Zakończ quiz';
                    }
                } else {
                    this._showLastStep();
                }
            }).catch(error => {
                this._handleError(error);
            })
        } else {
            this._handleError('no_answer_selected');
        }
    }

    _generateQuestion(question){
        if(question.answers.length > 0){
            this.question.innerText = question.question;
            this.answersContainer.innerHTML = '';
            question.answers.forEach((answer, key) => {
                let answerElement = this._createAnswerElement(key, answer.answer);
                this.answersContainer.appendChild(answerElement);
            });
        }
    }

    _showLastStep(){
        let form = this._generateForm();

        form.addEventListener('submit', this._sendForm);

        let msg = document.createElement('div');

        let score = document.createElement('div');
        score.classList.add('lesson__quiz-score');
        score.classList.add('lesson__quiz-score--static');
        score.innerHTML = 'Rozwiązałes quiz.';

        let info = document.createElement('div');
        info.classList.add('lesson__quiz-info');
        info.innerHTML = 'Jeśli chcesz poznać swoje wyniki i wziąć udział w konkursie zostaw swoje dane poniżej!';

        msg.append(score);
        msg.append(info);

        this.formContainer.append(msg);
        this.formContainer.append(form);
        this.questionContainer.style.display = 'none';
        this.nextButton.style.display = 'none';
        this.formContainer.style.display = 'block';
    }

    _sendForm(e){
        e.preventDefault();
        let data = {
            score: this.score,
            time: this.fullTime,
            name: '',
            email: ''
        };

        let nameInput = this.formContainer.querySelector('[name="name"]');
        let emailInput = this.formContainer.querySelector('[name="email"]');
        if(nameInput !== null) data.name = nameInput.value;
        if(emailInput !== null) data.email = emailInput.value;

        this._submitForm(data).then(result => {
            this.formContainer.innerHTML = '';
            this.formContainer.style.display = 'none';
            this.scoreContainer.classList.add('lesson__quiz-score--static');
            this.scoreContainer.classList.add('lesson__quiz-score--static-end');
            this.scoreContainer.innerText = 'Dziekujemy za przesłanie odpowiedzi.';
            this.scoreContainer.style.display = 'block';
        }).catch(error => {
            this._handleError(error);
        })
    }

    _generateForm(){
        let form = document.createElement('form');
        form.classList.add('form')

        let form_row_name = document.createElement('div');
        form_row_name.classList.add('form__row');

        let form_input_group_name = document.createElement('div');
        form_input_group_name.classList.add('form__input-group');

        let form_input_label_name = document.createElement('label');
        form_input_label_name.setAttribute('for', 'name');
        form_input_label_name.innerText = 'Imię';

        let nameInput = document.createElement('input');
        nameInput.setAttribute('name', 'name');
        nameInput.setAttribute('type', 'text');
        nameInput.setAttribute('required', 'required');
        nameInput.setAttribute('placeholder', 'Imię');

        let form_input_group_email = document.createElement('div');
        form_input_group_email.classList.add('form__input-group');

        let form_input_label_email = document.createElement('label');
        form_input_label_email.setAttribute('for', 'name');
        form_input_label_email.innerText = 'Email';

        let emailInput = document.createElement('input');
        emailInput.setAttribute('name', 'email');
        emailInput.setAttribute('type', 'email');
        emailInput.setAttribute('required', 'required');
        emailInput.setAttribute('placeholder', 'Email');

        let form_row_rules = document.createElement('div');
        form_row_rules.classList.add('form__row');

        let label_rules = document.createElement('label');
        label_rules.classList.add('form__rules');

        let agreement = document.createElement('input');
        agreement.setAttribute('type', 'checkbox');
        agreement.setAttribute('required', 'required');
        agreement.setAttribute('id', 'agreement');

        let span = document.createElement('span');
        span.innerHTML = 'Wypełnienie formularza oznacza, że podane w nim dane osobowe będą przetwarzane w celu nawiązania kontaktu zgodnie z <a href="/regulamin/" target="_blank">regulaminem konkursu</a>.';

        let form_row_button = document.createElement('div');
        form_row_button.classList.add('form__row');

        let button = document.createElement('button');
        button.setAttribute('type', 'submit');
        button.innerHTML = 'Prześlij';
        button.classList.add('button');
        button.classList.add('button--bg-blue');
        // button.classList.add('button button--bg-blue');

        form_input_group_name.append(form_input_label_name);
        form_input_group_name.append(nameInput);

        form_input_group_email.append(form_input_label_email);
        form_input_group_email.append(emailInput);

        form_row_name.append(form_input_group_name);
        form_row_name.append(form_input_group_email);

        label_rules.append(agreement);
        label_rules.append(span);
        form_row_rules.append(label_rules);

        form_row_button.append(button);

        form.append(form_row_name);
        form.append(form_row_rules);
        form.append(form_row_button);
        return form;
    }

    _handleError(error){
        console.log(error);
        let element = document.createElement('div');

        const modal = document.createElement('div');
        const close = document.createElement('a');
        close.classList.add('modal__close');
        close.innerHTML = '×';

        modal.classList.add('modal');
        modal.classList.add('modal--quiz-error');
        modal.setAttribute('data-element', 'modal');

        if (error === 'no_answer_selected') {
            modal.innerText = 'Musisz wybrać odpowiedź.';
        } else {
            modal.innerText = 'Wystąpił błąd, odśwież stronę i spróbuj ponownie.';
        }

        modal.appendChild(close);
        element.appendChild(modal);

        let instance = basicLightbox.create(element);
        instance.show();

        close.addEventListener('click', (e) => {
            instance.close();
            instance.innerHTML = '';
        });
        // alert(error);
    }

    _createAnswerElement(id, answer){
        let answerElement = document.createElement('div');
        answerElement.classList.add('lesson__quiz-answer');
        let input = document.createElement('input');
        input.setAttribute('type', 'radio');
        input.setAttribute('name', 'answer');
        input.setAttribute('value', id);
        input.setAttribute('id', 'answer-'+id);
        answerElement.appendChild(input);
        let label = document.createElement('label');
        label.setAttribute('for', 'answer-'+id);
        if(this._isValidURL(answer)){
            let image = document.createElement('img');
            image.setAttribute('src', answer);
            label.appendChild(image);
        } else {
            label.innerHTML = answer;
        }
        answerElement.appendChild(label);
        return answerElement;
    }

    _isValidURL(u){
        let elm = document.createElement('input');
        elm.setAttribute('type', 'url');
        elm.value = u;
        return elm.validity.valid;
    }

    _getCheckedAnswer(){
        let checked = this.answersContainer.querySelector('[name="answer"]:checked');
        if(checked !== null){
            return checked.getAttribute('value');
        }
        return -1;
    }

    _checkAnswer(answer){
        let url = '/wp-json/netivo/v1/quiz/';
        return fetch(url, {
            method: 'POST',
            cache: 'no-cache',
            credentials: "same-origin",
            redirect: "follow",
            body: 'question='+this.currentQuestion+'&answer='+answer,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then(response => {
            if (!response.ok) {
                return response.json().then(function(response) {
                    throw Error(response.code);
                })
            } else {
                return response.json();
            }
        });
    }
    _getQuestion(question){
        let url = '/wp-json/netivo/v1/quiz/?question='+question;
        return fetch(url, {
            method: 'GET',
            cache: 'no-cache',
            credentials: "same-origin",
            redirect: "follow",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (!response.ok) {
                return response.json().then(function(response) {
                    throw Error(response.code);
                })
            } else {
                return response.json();
            }
        });
    }
    _submitForm(data){
        let formBody = Object.keys(data).map(key => {
            if (typeof data[key] === "object" && !Array.isArray(data[key]) && data[key] !== null) {
                return Object.keys(data[key]).map(k => {
                    return key + '[' + k + ']=' + data[key][k];
                }).join('&');
            }
            return key + '=' + data[key]
        }).join('&');
        let url = '/wp-json/netivo/v1/quiz/';
        return fetch(url, {
            method: 'PUT',
            cache: 'no-cache',
            credentials: "same-origin",
            redirect: "follow",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: formBody
        }).then(response => {
            if (!response.ok) {
                return response.json().then(function(response) {
                    throw Error(response.code);
                })
            } else {
                return response.json();
            }
        });
    }

}

export default MainQuiz;