class LessonMap {
    constructor(selector) {
        this.selector = selector;
        this.history_timeline = document.querySelector('.js-history-timeline');
        this.isMobile = false;

        if (window.innerWidth <= 570) {
            this.isMobile = true;
        }

        this.points = this.selector.querySelectorAll('[data-element="point"]');
        if (this.points.length > 0) {
            this.points.forEach(point => {
                point.addEventListener('click', (e) => {

                    this._clearActivePoints();

                    if (!point.classList.contains('lesson-map__point--active')) {
                        point.classList.add('lesson-map__point--active');
                    }

                    if (this.history_timeline !== null) {
                        let date = point.getAttribute('data-element-date');

                        this._clearActiveDate();
                        if (date !== '') {
                            this._setActiveDate(date);
                        }
                    }
                });
                if (this.isMobile) {
                    let close = document.createElement('a');
                    close.classList.add('lesson-map__point-close');
                    close.innerText = 'x';

                    let box = point.querySelector('.lesson-map__box');
                    if (box !== null) {
                        point.append(close);

                        close.addEventListener('click', e => {
                            e.preventDefault();

                            if (point.classList.contains('lesson-map__point--active')) {
                                point.classList.remove('lesson-map__point--active');
                            }
                        });
                    }
                }
            });
        }
    }
    _clearActivePoints() {
        this.points.forEach(p => {
            p.classList.remove('lesson-map__point--active');
        });
    }
    _setActiveDate(date) {
        this.history_timeline.querySelector('[data-value="' + date + '"]').classList.add('history-timeline__item--active');
    }
    _clearActiveDate() {
        let dates = this.history_timeline.querySelectorAll('.history-timeline__item');
        if (dates.length > 0) {
            dates.forEach(d => {
                if (d.classList.contains('history-timeline__item--active')) {
                    d.classList.remove('history-timeline__item--active');
                }
            });
        }
    }
}
export default LessonMap;