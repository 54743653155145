import * as basicLightbox from "basiclightbox";

class PostsList {
    constructor(selector) {
        this.selector = selector;
        this.posts = this.selector.querySelectorAll('[data-element="single-post"]');
        this.loader = null;
        this.post = null;
        this.isLoading = false;

        if (this.posts !== null) {
            this.posts.forEach(post => {
                post.addEventListener('click', (e) => {
                    e.preventDefault();
                    if (!this.isLoading) {
                        this._loadPostData(e);
                    }
                });
            });
        }
    }

    _getDataFromAPI(post_id) {
        let url = '/wp-json/wp/v2/posts/' + post_id;
        return fetch(url, {
            method: 'GET',
            cache: 'no-cache',
            credentials: "same-origin",
            redirect: "follow",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (!response.ok) {
                return response.json().then(function(response) {
                    throw Error(response.code);
                })
            } else {
                return response.json();
            }
        });
    }

    _showLoader(loader) {
        loader.classList.add('show');
    }

    _hideLoader(loader) {
        loader.classList.remove('show');
    }

    _handleError(error) {
        // Wystapił problem
    }

    _falseLoading() {
        // Pusty wpis
    }

    _loadPostData(e) {
        this.post = e.target.closest('[data-element="single-post"]');
        this.loader = this.post.querySelector('[data-element="loader"]');
        if (this.loader !== null) {
            this._showLoader(this.loader);
        }
        this.post.classList.add('single-post--is-loading');
        this.isLoading = true;

        let post_id = this.post.getAttribute('data-post-id');

        this._getDataFromAPI(post_id).then(response => {
            if(response !== null){
                setTimeout(() => {
                    this._displayModal(response);
                }, 500);
            } else {
                this._falseLoading();
            }
        }).catch(error => {
            this._handleError(error);
        })
    }

    _displayModal(res) {
        let div = document.createElement('div');
        let modal = document.createElement('div');
        modal.classList.add('modal');
        let close = document.createElement('a');
        close.setAttribute('href', '#');
        close.setAttribute('data-element', 'close');
        close.classList.add('modal__close');
        close.innerText = '×';
        let content = document.createElement('div');
        content.classList.add('modal__content');
        let container = document.createElement('div');
        container.classList.add('container');
        container.classList.add('modal-post');

        let post_vignette = document.createElement('img');
        post_vignette.setAttribute('alt', 'winieta');
        post_vignette.setAttribute('src', '/wp-content/themes/ddm/dist/images/vignette.png');
        post_vignette.classList.add('modal-post__vignette');

        let post_title = document.createElement('div');
        post_title.innerHTML = res.title.rendered;
        post_title.classList.add('modal-post__title');

        let post_content = document.createElement('div');
        post_content.innerHTML = res.content.rendered;
        post_content.classList.add('modal-post__content');

        modal.appendChild(close);
        container.appendChild(post_vignette);
        container.appendChild(post_title);
        container.appendChild(post_content);
        content.appendChild(container);
        modal.appendChild(content);
        div.appendChild(modal);

        if (this.loader !== null) {
            this._hideLoader(this.loader);
        }
        this.isLoading = false;
        this.post.classList.remove('single-post--is-loading');

        let instance = basicLightbox.create(div);
        instance.show(() => {
            document.querySelector('html').style.overflowY = 'hidden';
            document.querySelector('body').classList.add('overflow-hidden');
        });

        close.addEventListener('click', (e) => {
            e.preventDefault();

            instance.close(() => {
                if (window.innerWidth <= 767) {
                    document.querySelector('html').style.overflowY = 'auto';
                }
                document.querySelector('body').classList.remove('overflow-hidden');
            });
        });

        this.loader = null;
        this.post = null;
    }
}
export default PostsList;