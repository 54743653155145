import Cookie from "js-cookie";
import * as basicLightbox from "basiclightbox";

class ColoringBook {
    constructor(selector) {
        this.selector = selector;
        this.post_id = this.selector.getAttribute('data-id');
        this.level = this.selector.getAttribute('data-level');
        this.end_button = this.selector.querySelector('[data-element="end"]');
        this.file = this.selector.querySelector('[data-element="book"]');
        this.colors_toolbar = this.selector.querySelector('[data-element="colors"]');
        this.counter = this.selector.querySelector('[data-element="counter"]');
        this.current_color = '';
        this.items = this.file.querySelectorAll('[data-item]');
        this.items_count = this.items.length;
        this.isMobile = false;

        this._setCurrentColor = this._setCurrentColor.bind(this);
        this._clickItem = this._clickItem.bind(this);

        if (this.colors_toolbar !== null) {
            let colors = this.colors_toolbar.querySelectorAll('[data-color]');
            if (colors.length > 0) {
                colors.forEach(color => {
                    color.addEventListener('click', this._setCurrentColor);
                });
            }
        }

        if (this.items.length > 0) {
            this.items.forEach(item => {
                item.addEventListener('click', this._clickItem);
            });
        }

        if (window.innerWidth <= 640) {
            this.isMobile = true;
        }

        this.counter.innerText = 'Pokolorowanych pól: 0/' + this.items.length;
    }
    _clearActiveColor() {
        let colors = this.colors_toolbar.querySelectorAll('[data-color]');
        if (colors.length > 0) {
            colors.forEach(color => {
                if (color.classList.contains('lesson__color--active')) {
                    color.classList.remove('lesson__color--active');
                }
            });
        }
    }
    _setCurrentColor(event) {
        event.preventDefault();

        let item = event.target;
        this.current_color = item.getAttribute('data-color');
        this._clearActiveColor();

        item.classList.add('lesson__color--active');
    }
    _clickItem(event) {
        event.preventDefault();

        let item = event.target;

        if (this.current_color === '') {
            this._handleError('color_not_selected');
        } else {
            item.style.fill = this.current_color;
            item.classList.add('colored');
            this._checkProgress();
        }
    }
    _checkProgress() {
        let count = 0;
        this.items.forEach(item => {
           if (item.classList.contains('colored')) {
               count++;
           }
        });

        this.counter.innerText = 'Pokolorowanych pól: ' + count + '/' + this.items.length;

        if (count === this.items_count) {
            this._setLessonCleared();
            this.end_button.parentNode.style.pointerEvents = 'initial';
            this.end_button.classList.remove('button--disabled');
            if (this.isMobile === true) {
                window.scrollTo({
                    top: this.end_button.getBoundingClientRect().top + window.pageYOffset - 200
                });
            }
        }
    }
    _setLessonCleared(){
        let current = Cookie.get('completed_'+this.level);
        let cleared = [];
        if(current !== undefined){
            cleared = current.split(',');
        }
        if (!cleared.includes(this.post_id)) {
            cleared.push(this.post_id);
        }
        Cookie.set('completed_'+this.level, cleared.join(','), { expires: 365 });
    }
    _handleError(error) {
        if (error === 'color_not_selected') {
            let element = document.createElement('div');

            const modal = document.createElement('div');
            const close = document.createElement('a');
            close.classList.add('modal__close');
            close.innerHTML = '×';

            modal.classList.add('modal');
            modal.classList.add('modal--quiz-error');
            modal.setAttribute('data-element', 'modal');

            modal.innerText = 'Musisz wybrać kolor!';

            modal.appendChild(close);
            element.appendChild(modal);

            let instance = basicLightbox.create(element);
            instance.show();

            close.addEventListener('click', (e) => {
                instance.close();
                instance.innerHTML = '';
            });
        }
    }
}
export default ColoringBook;