import * as basicLightbox from "basiclightbox";

class PublicationsList {
    constructor(selector, options={}) {
        this.selector = selector;
        this.options = options;

        this.loadMore = this.selector.querySelector('[data-element="load-more"]');
        this.filterForm = this.selector.querySelector('[data-element="form"]');
        this.postContainer = this.selector.querySelector('[data-element="container"]');
        this.loadMorePoint = this.selector.querySelector('[data-element="load-more-point"]');
        this.loader = this.selector.querySelector('[data-element="loader"]');

        this.lastPage = false;
        this.isLoading = false;

        this.event = new Event('publicationsLoaded');

        this._submitForm = this._submitForm.bind(this);

        this._scrollEvent = this._scrollEvent.bind(this);
        this._nextPageEvent = this._nextPageEvent.bind(this);

        this._loadAttributes();

        this._initActions();

        if(this.loadOnInit){
            this.postContainer.innerHTML = '';
            this._loadPosts();
        }
    }

    _loadAttributes(){
        let types = this.selector.getAttribute('data-type');
        if(types !== null && types !== '') {
            this.type = types;
        } else this.type = 'posts';

        let categories = this.selector.getAttribute('data-category');
        if(categories !== null && categories !== ''){
            this.categories = categories.split(',');
        } else this.categories = [];

        let search = this.selector.getAttribute('data-search');
        if(search !== null && search !== ''){
            this.search = search;
        } else this.search = '';

        let page = this.selector.getAttribute('data-page');
        if(page !== null && page !== ''){
            this.page = parseInt(page);
        } else this.page = 1;

        let perPage = this.selector.getAttribute('data-per-page');
        if(perPage !== null && perPage !== ''){
            this.perPage = parseInt(perPage);
        } else this.perPage = 10;

        let loadOnInit = this.selector.getAttribute('data-load-on-init');
        if(loadOnInit !== null && loadOnInit !== ''){
            this.loadOnInit = (loadOnInit === 'true');
        } else this.loadOnInit = false;
    }

    _initActions(){
        if (this.filterForm !== null) {
            this.filterForm.addEventListener('submit', this._submitForm);
        }

        // if (window.innerWidth > 640) {
        //     if (this.loadMorePoint !== null) {
        //         window.addEventListener('scroll', this._scrollEvent);
        //     }
        // } else {
            if (this.loadMore !== null) {
                this.loadMore.addEventListener('click', this._nextPageEvent);
            }
        // }
    }

    _submitForm(event) {
        event.preventDefault();
        this.page = 1;
        this._getFormData();
        this.postContainer.innerHTML = '';
        this._loadPosts();
    }

    _nextPageEvent(event){
        event.preventDefault();
        if(!this.lastPage) {
            this._nextPage();
        }
    }

    _scrollEvent(event){
        let window_width = window.innerWidth;
        let window_height = window.innerHeight;
        let window_top = document.body.scrollTop - 100;
        if (window.innerHeight < 800) {
            window_top = document.body.scrollTop;
        }
        let window_bottom = (window_top + window_height);

        let element_top = document.body.scrollTop + this.loadMorePoint.getBoundingClientRect().top;

        if ((element_top >= window_top) && element_top <= window_bottom && !this.isLoading && !this.lastPage) {
            this._nextPage();
        }
    }

    _nextPage(){
        this.page++;
        this._loadPosts();
    }

    _getFromAPI(){
        let queryString = this._buildQuery();
        let url = '/wp-json/wp/v2/' + this.type + '?'+queryString;
        return fetch(url, {
            method: 'GET',
            cache: 'no-cache',
            credentials: "same-origin",
            redirect: "follow",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (!response.ok) {
                return response.json().then(function(response) {
                    throw Error(response.code);
                })
            } else {
                return response.json();
            }
        });
    }

    _buildQuery(){
        let query = [
            'per_page=' + this.perPage,
            'page=' + this.page
        ];

        if(this.categories.length > 0){
            query.push('categories=' + this.categories.join(','));
        }

        if(this.search !== ''){
            query.push('search=' + this.search);
            query.push('orderby=relevance');
        }
        query.push('_embed');

        return query.join('&');
    }

    _loadPosts(){
        this._showLoaderAnimation();
        this.isLoading = true;
        this._getFromAPI().then(response => {
            if(response.length > 0){
                this._generatePosts(response);
            } else {
                this._noResults();
            }
        }).catch(error => {
            this._handleError(error);
        })
    }

    _generatePosts(posts){
        posts.forEach(post => {
            let postElement = this.options.generatePost(post, this);
            this.postContainer.append(postElement);
        });
        this._hideLoaderAnimation();
        window.dispatchEvent(this.event);
        if(posts.length <= this.perPage){
            this.lastPage = true;
            this._hideLoadMoreButton();
        }
        this.isLoading = false;
    }

    _nt_excerpt(string, limit) {
        return string.split(" ").splice(0, limit).join(" ");
    }

    _noResults(){
        if(this.page === 1){
            let element = this.options.noResults(this);
            this.postContainer.appendChild(element);
        } else {
            this.lastPage = true;
            this._hideLoadMoreButton();
        }
    }

    _handleError(error){
        // console.log(error);
        if (error.message === 'rest_post_invalid_page_number') {
            this.lastPage = true;
            this._hideLoadMoreButton();
        } else {
            this.options.handleError(error, this);
        }
        this._hideLoaderAnimation();
        this.isLoading = false;
    }

    _showLoaderAnimation() {
        this.loader.classList.add(this.options.activeLoaderClass);
    }
    _hideLoaderAnimation() {
        this.loader.classList.remove(this.options.activeLoaderClass);
    }
    _hideLoadMoreButton() {
        this.loadMore.style.display = 'none';
    }
    _getFormData() {
        let categories = this.filterForm.querySelector('[data-element="categories"]');
        this.categories = [];
        categories.querySelectorAll('.custom-checkbox').forEach(item => {
            if (item.checked) {
                this.categories.push(item.value);
            }
        });

        let search = this.filterForm.querySelector('[name="s"]');
        if (search !== null) {
            if (search.value !== '') {
                this.search = search.value;
            }
        }
    }
}
export default PublicationsList;