/**
 *  Created by Michał on 11.05.2017.
 */
import * as basicLightbox from "basiclightbox";
import GLightbox from "glightbox";
import PostsList from "./postsList";
import Glide from "@glidejs/glide/entry/entry-complete";
import Map from "./map";
import YoutubePlayer from "./youtubePlayer";
import PublicationsList from "./publicationsList";
import LessonMap from "./lessonMap";
import LessonAudio from "./lessonAudio";
import Quiz from "./quiz";
import ColoringBook from "./coloringBook";
import PostsListSingle from "./postsListSingle";
import MainQuiz from "./mainQuiz";
import ColoringBookModal from "./coloringBookModal";

function detectmob() {
    return ( navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)
    );
}
function toggleMenu(){
    let menu = document.querySelector('#mobile-menu');
    let mask = document.querySelector('#mask');
    let wrapper = document.querySelector('#wrapper');
    let content = document.querySelector('#content');
    document.querySelector('.js-hamburger').classList.toggle("active");
    // document.querySelector('#mask').toggle();
    menu.classList.toggle("toggled");
    mask.classList.toggle('shown');
    content.classList.toggle("toggled");
    if(menu.classList.contains("toggled")) {
        // wrapper.css('height', menu.height() + 'px');
        document.body.style.overflow = 'hidden';
        document.querySelector('html').style.overflow = 'hidden';
    }
    else {
        // wrapper.css('height', 'auto');
        document.body.style.overflow = 'auto';
        document.querySelector('html').style.overflow = 'auto';
    }
}

let mask = document.querySelector('#mask');
if (mask !== null) {
    let menu = document.querySelector('#mobile-menu');
    mask.addEventListener('click', (e) => {
        mask.classList.toggle('shown');
        menu.classList.toggle('toggled');
        if(!menu.classList.contains("toggled")) {
            document.body.style.overflow = 'auto';
            document.querySelector('html').style.overflow = 'auto';
        }
    });
}

let hamburger = document.querySelector('.js-hamburger');
if(hamburger !== null){
    hamburger.addEventListener('click', event => {
       event.preventDefault();
       toggleMenu();
    });
}

let mobile_close = document.querySelector('.js-mobile-close');
if (mobile_close != null) {
    mobile_close.addEventListener('click', function(e) {
        let menu = document.querySelector('#mobile-menu');
        if (menu != null && menu.classList.contains('toggled')) {
            let mask = document.querySelector('#mask');

            e.preventDefault();
            document.body.style.overflow = 'initial';
            document.querySelector('html').style.overflow = 'initial';
            menu.classList.remove('toggled');
            mask.classList.remove('shown');
            document.querySelector('.js-hamburger').classList.toggle("active");
        }
    });
}

// let header = document.querySelector('.js-header');
let body = document.querySelector('body');
// let header_height_non_sticky = 0;

window.addEventListener('DOMContentLoaded', () => {

    setTimeout(() => {
        let interactive_elements = document.querySelectorAll('.interactive-element');

        let window_width = window.innerWidth;
        let window_height = window.innerHeight;
        let window_top = document.body.scrollTop - 200;
        let window_bottom = (window_top + window_height);

        for (const inter_element of interactive_elements) {
            let element_height = inter_element.clientHeight;
            let element_top = document.body.scrollTop + inter_element.getBoundingClientRect().top;
            let element_bottom = (element_top + element_height) - 20;

            if ((element_bottom >= window_top) && element_top <= window_bottom) {
                inter_element.classList.add('visible');
            }
        }
    }, 100);

    let lma = null;
    let lm_authors_carousel = document.querySelector('.js-lm-authors');
    if (lm_authors_carousel !== null) {
        let prev_button = lm_authors_carousel.parentNode.querySelector('[data-element="button-prev"]');
        let next_button = lm_authors_carousel.parentNode.querySelector('[data-element="button-next"]');

        lma = new Glide(lm_authors_carousel, {
            type: 'carousel',
            perView: 4,
            autoplay: 0,
            animationDuration: 500,
            gap: 40,
            //clones: false,
            animationTimingFunc: 'linear',
            //bound: true,
            breakpoints: {
                500: {
                    autoplay: 4500,
                    perView: 1,
                },
                767: {
                    autoplay: 0,
                    perView: 2,
                },
                991: {
                    autoplay: 0,
                    perView: 3,
                }
            }
        }).mount();

        prev_button.addEventListener('click', (e) => {
            e.preventDefault();

            lma.go('<');
        });
        next_button.addEventListener('click', (e) => {
            e.preventDefault();

            lma.go('>');
        });
    }


    let gc = null;
    let gallery_carousel = document.querySelector('.js-lesson-gallery');
    if (gallery_carousel !== null) {
        gc = new Glide(gallery_carousel, {
            type: 'carousel',
            perView: 1,
            autoplay: 5000,
            animationDuration: 500,
            gap: 0,
            //clones: false,
            animationTimingFunc: 'linear'
        }).mount();
    }

    let maps = document.querySelectorAll('.js-map');
    if (maps.length > 0) {
        maps.forEach(map => {
            new Map(map);
        });
    }

    let youtube_players = document.querySelectorAll('.js-youtube-player');
    if (youtube_players.length > 0) {
        youtube_players.forEach(player => {
            new YoutubePlayer(player);
        });
    }

    let posts_lists = document.querySelectorAll('.js-posts-list');
    if (posts_lists.length > 0) {
        posts_lists.forEach(item => {
            new PostsList(item);
        });
    }

    let handleError = (error, list) => {
        let element = document.createElement('div');

        const modal = document.createElement('div');
        const close = document.createElement('a');
        close.classList.add('modal__close');
        close.innerHTML = '×';

        modal.classList.add('modal');
        modal.classList.add('modal--load-posts-error');
        modal.setAttribute('data-element', 'modal');
        modal.innerText = 'Wystąpił błąd podczas ładowania publikacji, odśwież stronę i spróbuj ponownie.';

        modal.appendChild(close);
        element.appendChild(modal);

        let instance = basicLightbox.create(element);
        instance.show();

        close.addEventListener('click', (e) => {
            instance.close();
            instance.innerHTML = '';
        });
    };

    let news_post_list = document.querySelector('.js-news-loading');
    if (news_post_list !== null) {
        new PublicationsList(news_post_list, {
            generatePost: (post, list) => {

                let element = document.createElement('div');
                element.classList.add('single-post');
                element.classList.add('single-post--news');

                let img_src = post._embedded["wp:featuredmedia"][0].source_url;
                let img = document.createElement('img');
                img.classList.add('single-post__thumbnail');
                img.setAttribute('alt', post.title.rendered);
                if (img_src !== '') {
                    img.setAttribute('src', img_src);
                    element.append(img);
                }

                let title = document.createElement('div');
                title.classList.add('single-post__title');
                title.innerHTML = post.title.rendered;

                let content = document.createElement('div');
                content.classList.add('single-post__content');
                content.innerHTML = post.content.rendered;

                element.append(title);
                element.append(content);
                return element;
            },
            noResults: (list) => {
                let element = document.createElement('div');
                element.classList.add('title');
                element.innerText = 'Brak wpisów spełniających kryteria';
                return element;
            },
            handleError: handleError,
            activeLoaderClass: 'active'
        });
    }

    let article_post_list = document.querySelector('.js-articles-loading');
    if (article_post_list !== null) {
        new PublicationsList(article_post_list, {
            generatePost: (post, list) => {
                let element = document.createElement('div');
                element.classList.add('single-post');
                element.setAttribute('data-element', 'single-post');
                element.setAttribute('data-post-id', post.id);

                let title = document.createElement('div');
                title.classList.add('single-post__title');
                title.innerHTML = post.title.rendered;

                let content = document.createElement('div');
                content.classList.add('single-post__content');
                content.innerHTML = post.content.rendered;

                let loader_ctnr = document.createElement('div');
                loader_ctnr.classList.add('single-post__loader');
                loader_ctnr.setAttribute('data-element', 'loader');

                let loader = document.createElement('div');
                loader.classList.add('lds-roller');

                let l_1 = document.createElement('div');
                let l_2 = document.createElement('div');
                let l_3 = document.createElement('div');
                let l_4 = document.createElement('div');
                let l_5 = document.createElement('div');
                let l_6 = document.createElement('div');
                let l_7 = document.createElement('div');
                let l_8 = document.createElement('div');

                loader.append(l_1);
                loader.append(l_2);
                loader.append(l_3);
                loader.append(l_4);
                loader.append(l_5);
                loader.append(l_6);
                loader.append(l_7);
                loader.append(l_8);

                loader_ctnr.append(loader);

                element.append(title);
                element.append(content);
                element.append(loader_ctnr);

                new PostsListSingle(element);

                return element;
            },
            noResults: (list) => {
                let element = document.createElement('div');
                element.classList.add('title');
                element.innerText = 'Brak wpisów spełniających kryteria';
                return element;
            },
            handleError: handleError,
            activeLoaderClass: 'active'
        });
    }

    let am_post_list = document.querySelector('.js-additional-materials-loading');
    if (am_post_list !== null) {
        new PublicationsList(am_post_list, {
            generatePost: (post, list) => {

                let element = document.createElement('div');
                element.classList.add('single-post');

                let title = document.createElement('div');
                title.classList.add('single-post__title');
                title.innerHTML = post.title.rendered;

                let content = document.createElement('div');
                content.classList.add('single-post__content');
                content.innerHTML = post.content.rendered;

                element.append(title);
                element.append(content);

                let url = post.meta._nt_additional_material_file;
                let ext = url.split('.').pop();

                let download = document.createElement('div');
                download.classList.add('single-post__download');

                let span = document.createElement('span');
                if (ext !== '') {
                    span.innerText = ext;
                }

                let link = document.createElement('a');
                if (url !== '') {
                    link.setAttribute('href', url);
                    link.setAttribute('target', '_blank');
                    link.innerText = 'Pobierz';
                }

                download.append(span);
                download.append(link);

                element.append(download);
                return element;
            },
            noResults: (list) => {
                let element = document.createElement('div');
                element.classList.add('title');
                element.innerText = 'Brak wpisów spełniających kryteria';
                return element;
            },
            handleError: handleError,
            activeLoaderClass: 'active'
        });
    }

    let lesson_maps = document.querySelectorAll('.js-lesson-map');
    if (lesson_maps.length > 0) {
        lesson_maps.forEach(lm => {
            new LessonMap(lm);
        })
    }

    let lesson_audios = document.querySelectorAll('.js-lesson-audio');
    if (lesson_audios.length > 0) {
        lesson_audios.forEach(audio => {
           new LessonAudio(audio);
        });
    }

    let quizes = document.querySelectorAll('.js-quiz');
    if (quizes.length > 0) {
        quizes.forEach(quiz => {
            new Quiz(quiz);
        });
    }
    let mainquizes = document.querySelectorAll('.js-main-quiz');
    if (mainquizes.length > 0) {
        mainquizes.forEach(quiz => {
            new MainQuiz(quiz);
        });
    }

    let coloring_books = document.querySelectorAll('.js-coloring-book');
    if (coloring_books.length > 0) {
        coloring_books.forEach(book => {
           new ColoringBook(book);
        });
    }

    let coloring_book_modal = document.querySelector('.js-coloring-book-modal');
    if (coloring_book_modal !== null) {
        new ColoringBookModal(coloring_book_modal);
    }

    // let lesson_gallery = document.querySelectorAll('.nt-lightbox a');
    // if(lesson_gallery !== null) {
    //     lesson_gallery.forEach(el => {
    //         let instance = GLightbox({
    //             elements: lesson_gallery,
    //         });
    //     });
    // }
    new GLightbox();
});

window.addEventListener('click', (e) => {
    //console.log(e.target);
    if (!e.target.closest('.lesson-map__point')) {
        let lesson_map = document.querySelector('.js-lesson-map');
        //console.log(lesson_map);
        if (lesson_map !== null) {
            let point = lesson_map.querySelector('.lesson-map__point--active');
            if (point !== null) {
                point.classList.remove('lesson-map__point--active');
                let history_timeline_point = document.querySelector('.js-history-timeline').querySelector('.history-timeline__item--active');
                if (history_timeline_point !== null) {
                    history_timeline_point.classList.remove('history-timeline__item--active');
                }
            }
        }
    }
});

// window.onscroll = function(){stickyStart()};

let header = document.querySelector('.header');
// let sticky = header.offsetTop;
// console.log(header);
// console.log(sticky);

// function stickyStart() {
//     console.log('start');
//     if(window.pageYOffset > sticky){
//         header.style.background = '#fff';
//         header.classList.add('header--scrolled');
//     } else {
//         header.style.background = 'transparent';
//         header.classList.remove('header--scrolled');
//     }
// }

window.addEventListener('scroll', () => {
    let interactive_elements = document.querySelectorAll('.interactive-element');

    let window_width = window.innerWidth;
    let window_height = window.innerHeight;
    let window_top = document.body.scrollTop - 200;
    let window_bottom = (window_top + window_height);

    for (const inter_element of interactive_elements) {
        let element_height = inter_element.clientHeight;
        let element_top = document.body.scrollTop + inter_element.getBoundingClientRect().top;
        let element_bottom = (element_top + element_height) - 500;

        if ((element_bottom >= window_top) && element_top <= window_bottom) {
            inter_element.classList.add('visible');
        }
    }
});